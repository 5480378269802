import { earningFragment } from '../fragments'

export const paymentMethodBasicFragment = `
    uuid
    name
    slug
`

export const paymentMethodFragment = `
    paymentMethod {
        uuid
        name
        slug
        active
    }
`

export const userPaymentMethodFragment = `
    uuid
    userUuid
    details
    status
    internalStatus
    internalStatusRejectionReason
    createdAt
    userHash
    sessionUuid
    ${paymentMethodFragment}
`

export const userPaymentMethodsFragment = `
    uuid
    userUuid
    details
    status
    internalStatus
    internalStatusRejectionReason
    createdAt
    userHash
    updatedAt
    bankTransferManualType
    ${paymentMethodFragment}
`

export const setPaymentMethodFragment = `
    uuid
    userUuid
    details
    status
    sessionUuid
    ${paymentMethodFragment}
`

export const setPaymentMethodForCurrencyFragment = `
    uuid 
    currencyCode 
    displayName 
    paymentMethodUuid 
`

export const paymentCommonFragment = `
    uuid
    userUuid
    amount
    adjustedAmount
    paymentDate
    netCommission
    status
    createdAt
    updatedAt
    isPayable
    isManuallyPaid
`

export const paymentFragment = `
    ${paymentCommonFragment}
    transfers {
        uuid
        type
        amount
        status
        isRetryable
        ${paymentMethodFragment}
    }
`

export const getPaymentFragment = `
    ${paymentCommonFragment}
    isEarly
    isEarlyPercentage
    transfers {
        uuid
        type
        amount
        status
        providerStatus
        createdAt
        updatedAt
        feeAmount
        paymentMethodUuid
        isRetryable
    }
`

export const getPaymentExtendedFragment = `
    ${getPaymentFragment}
    earnings {
        ${earningFragment}
    }
`

export const markAsPaidFragment = `
    ${paymentCommonFragment}
    transfers {
        uuid
        type
        amount
        status
        isRetryable
        ${paymentMethodFragment}
    }
`

export const massPaymentsFragment = `
    totalAmountUsd
    countOfPayments
    ${paymentMethodFragment}
    payments {
        uuid
        userUuid
        amount
        paymentDate
        netCommission
        status
        createdAt
        updatedAt
        isPayable
        isManuallyPaid
        type
        isEarlyPercentage
        createdBy
        isEarly
    }
`
